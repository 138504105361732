import { createStore } from "vuex";

// funzionamento di vuex con async
// cat FILE da IPFS ex. QmSqmXf9rke21BtCUMJzcZ1AB19LKr7X7ATK9JRQCWrHEg
// Introdurre duckDB per gestire i db come file .csv o .json
//const ipfs = async () => await this.$ipfs;

/*async function ddata() {
    for await (const chunk of ipfs.cat(
   "QmSqmXf9rke21BtCUMJzcZ1AB19LKr7X7ATK9JRQCWrHEg" <--- !!!
 )) {
    return new TextDecoder("utf-8").decode(chunk);
 }}*/

const store = createStore({
  state: {
    data: "nullo",
    memories: [
      {
        id: "m1",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Mighty_Mountains_with_Snow.jpg/640px-Mighty_Mountains_with_Snow.jpg",
        title: "A trip into the mountains",
        description: "It was a really nice trip!",
      },
      {
        id: "m2",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/A_surfer_surfing_on_the_ocean_%28Unsplash%29.jpg/640px-A_surfer_surfing_on_the_ocean_%28Unsplash%29.jpg",
        title: "Surfing the sea side",
        description: "Feeling the cool breeze",
      },
      {
        id: "m3",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Indian_-_Food.jpg/640px-Indian_-_Food.jpg",
        title: "Good eating",
        description: "Really tasty!",
      },
    ],
  },
  mutations: {
    loaddata(state, payload) {
      state.data = payload;
      console.log(state.data);
    },
  },
  getters: {
    memories(state) {
      return state.memories;
    },
    memory(state) {
      return (memoryId) => {
        return state.memories.find((memory) => memory.id === memoryId);
      };
    },
  },
});
export default store;

<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
            <ion-title> {{ pageTitle}}</ion-title>
            <IonBackButton color="dark" slot="start" :default-href="defback"></IonBackButton>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <slot />
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton} from '@ionic/vue'

export default {
    props: ['pageTitle', "defback"],
    components:{ IonPage, IonHeader, IonToolbar, IonTitle, IonContent,IonBackButton}
    
}

</script>
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">APK</ion-title>
        <ion-searchbar
          animated
          debounce="500"
          show-cancel-button="focus"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Tab 1</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card v-if="seen">
        <ion-card-header
          style="
            box-shadow: inset 0 0 6px whitesmoke, inset 2px 0 8px #f0f,
              inset -2px 0 8px #0ff, inset 2px 0 10px #f0f,
              inset -2px 0 3px #0ff, 0 0 5px #fff, -1px 0 8px #f0f,
              1px 0 8px #0ff;
          "
        >
          <ion-card-subtitle>Benvenuto</ion-card-subtitle>
          <ion-card-title
            >Ti diamo il benvenuto! <br />
            Guarde le ultime novità
          </ion-card-title>
          <ion-button color="danger" @click="getDataIPFS">Chiudi</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-item>
        <ion-label>In evidenza</ion-label>
      </ion-item>
      <ion-list style="overflow: auto; white-space: nowrap">
        <ion-item href="/blank" style="display: inline-block">
          <IonThumbnail slot="start">
            <ion-img
              src="https://play-lh.googleusercontent.com/VHB9bVB8cTcnqwnu0nJqKYbiutRclnbGxTpwnayKB4vMxZj8pk1220Rg-6oQ68DwAkqO"
            >
            </ion-img>
          </IonThumbnail>
          <ion-label color="primary">{{ $store.state.altro }}</ion-label>
        </ion-item>
        <ion-item href="/blank" style="display: inline-block">
          <IonThumbnail slot="start">
            <ion-img
              src="https://play-lh.googleusercontent.com/rbIaIyJTh9YtsXWbVR6qNfUFF1S-rgkBrEV8xrWH2zAZ1dvN1YC0QP0SKPXqSr-7fkQ"
            >
            </ion-img>
          </IonThumbnail>
          <ion-label>Play piano</ion-label>
        </ion-item>

        <ion-item href="/blank" style="display: inline-block">
          <IonThumbnail slot="start">
            <ion-img
              src="https://play-lh.googleusercontent.com/skRyWCB8mFINQzXcFjWmspir5fdKY3DcDlwpimYAHAwXgohZ2obOYwda67TgbnGg6w"
            >
            </ion-img>
          </IonThumbnail>
          <ion-label>Star Wars</ion-label>
        </ion-item>

        <ion-item href="/blank" style="display: inline-block">
          <IonThumbnail slot="start">
            <ion-img
              src="https://play-lh.googleusercontent.com/6Wp5XQn_WsRs0iGnGPQwZwblWdfmq9TTas7m-xF4Ztx3DWrrzXlV5S7YBIRXpkXObZE"
            >
            </ion-img>
          </IonThumbnail>
          <ion-label>{{ data }}</ion-label>
        </ion-item>
        <ion-item href="/blank" style="display: inline-block">
          <IonThumbnail slot="start">
            <ion-img
              src="https://play-lh.googleusercontent.com/jKU64njy8urP89V1O63eJxMtvWjDGETPlHVIhDv9WZAYzsSxRWyWZkUlBJZj_HbkHA"
            >
            </ion-img>
          </IonThumbnail>
          <ion-label>{{ seen }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonList,
    IonCard,
    IonItem,
    IonImg,
    IonThumbnail,
    IonLabel,
  } from "@ionic/vue";
  import { mapState, mapMutations } from "vuex";

  export default {
    components: { IonList, IonCard, IonItem, IonImg, IonThumbnail, IonLabel },
    data: function () {
      return {
        msg: null,
        altrotesto: "Load...",
        seen: 1,
        online: 0,
        id: "1",
      };
    },
    mounted: function () {
      //this.getIpfsNodeInfo();
      //this.getDataIPFS();
      //this.loaddata();
    },
    computed: {
      ...mapState(["data"]),
    },

    methods: {
      ...mapMutations(["loaddata"]),

      async getIpfsNodeInfo() {
        try {
          // Await for ipfs node instance.
          const ipfs = await this.$ipfs;
          // Call ipfs `id` method.
          // Returns the identity of the Peer.
          const { agentVersion, id } = await ipfs.id();
          this.agentVersion = agentVersion;
          this.id = id;
          // Set successful status text.
          this.online = ipfs.isOnline();
          console.info(agentVersion + " " + id);
        } catch (err) {
          // Set error status text.
          this.altrotesto = `Error: ${err}`;
        }
      },
      async getDataIPFS() {
        try {
          const ipfs = await this.$ipfs;
          for await (const chunk of ipfs.cat(
            "QmbJ6SXFLuNaXkNQQF2Mpkiv1qKyPDk2dGU8xXo7z87Xzg"
          )) {
            const msg = new TextDecoder("utf-8").decode(chunk);
            this.loaddata(msg)
          }
        } catch (err) {
          // Set error status text.
          this.msg = `Error: ${err}`;
        }
      },
    },

    /*
    async created() {
      // Await for ipfs node instance.
          const ipfs = await this.$ipfs;
          
          const { agentVersion, id } = await ipfs.id();
          this.agentVersion = agentVersion;
          this.id = id;
          this.altrotesto = "Connected to IPFS =)";
          this.online = ipfs.isOnline();
      for await (const chunk of ipfs.cat(
        "QmaV4caueS4vqP8bp8hFaRPjo3Lf3pkqpXEvg3NXkkdWrQ"
      )) {
        const msg = new TextDecoder("utf-8").decode(chunk);
        this.msg = msg;
      }
      const mss = ipfs.pin.add('QmaV4caueS4vqP8bp8hFaRPjo3Lf3pkqpXEvg3NXkkdWrQ');
      console.info(mss)
      for await (const chunk of ipfs.cat(
        "QmaV4caueS4vqP8bp8hFaRPjo3Lf3pkqpXEvg3NXkkdWrQ"
      )) {
        const ss = new TextDecoder("utf-8").decode(chunk);
        console.log(ss)
      }
      //this.$db.run(this.msg);
      //this.$db.run("INSERT INTO test12 VALUES (?,?), (?,?)", [1,111,2,222]);

      // Prepare a statement
      // const stmt = this.$db.exec("SELECT * FROM MOCK_DATA");
      // console.log(stmt[0].values)

      // Bind new values
      //stmt.bind({$start:1, $end:2});
      //while(stmt.step()) { //
      //const row = stmt.getAsObject();
      //const altrotesto = JSON.stringify(row);
      //this.altrotesto= altrotesto
      //}
    },
    */
    /*
mounted() {
    setInterval(() => {
      this.msg++
    }, 1000)
  } */
  };
</script>

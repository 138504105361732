import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaseLayout from "./components/Base.vue";
import { IonicVue } from "@ionic/vue";
import Ipfs from "./plugins/ipfs";
/* import initSqlJs from "sql.js";
import sqlWasm from "!!url-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm";*/

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const app = createApp(App).use(IonicVue).use(router).use(store);
app.component('base-layout', BaseLayout);

router.isReady().then(() => {
  app.use(Ipfs);
  app.mount("#app");
});

/*  async function loadDB() {
    try {
      const SQL = await initSqlJs({
        locateFile: () => sqlWasm,
      });
      return new SQL.Database();
    } catch (err) {
      console.log(err);
    }
  }
  loadDB().then((db) => (app.config.globalProperties.$db = db)); //https://www.bilibili.com/read/cv12300968
*/
